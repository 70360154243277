
import { useKeycloak } from '@react-keycloak/web';
import { useMutation } from '@tanstack/react-query';
import {Row, Col, Result, Spin, Typography } from 'antd';
import { addMonths, differenceInCalendarMonths, format } from 'date-fns';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import logo from '../assets/images/logo_anilorak.svg';
const { Title } = Typography;

export default ({user}) => {

    const {t} = useTranslation();
    console.log("user.membership", user.membership);

    const expirationDate = (() => {
      if (!user.membership) return '';
      const differenceInMonths = differenceInCalendarMonths(
        Date.now(),
        new Date(user.membership.createdAt),
      );
      return format(
        addMonths(new Date(), differenceInMonths === 0 ? 1 : differenceInMonths),
        'dd/MM/yyyyy',
      );
    })();


    return (
      <>
      <Row justify={'center'}>
          <Col>
            <Title level={2}>{t('userIsPremium')}</Title>
          </Col>
        </Row>
        <Row justify={'center'}>
        <Col>
          <Title level={5}>{t('expiration')} : {expirationDate}</Title>
        </Col>
      </Row>
      </>
    );
}