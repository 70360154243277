
import { useKeycloak } from '@react-keycloak/web';
import { useMutation } from '@tanstack/react-query';
import {Row, Col, Result, Spin, Typography, Button } from 'antd';
import { ActualOptions } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import robokassa from '../assets/images/robokassa.svg';
import stripe from '../assets/images/stripe.svg';

const { Title } = Typography;

const providerIcons = {
  'robokassa' : robokassa,
  'stripe' : stripe
}

export default ({axios}) => {

    const {t} = useTranslation();
    const [loadingPaymentUrl, setLoadingPaymentUrl] = useState({});
    const navigate = useNavigate();

      const doLoadPaymentProviders = async () => {
        let response;
        try {
          response = await axios.get("/paymentProviders")
        } catch (errorReq) {
          console.log(errorReq);
        }
        return response?.data;
      }

      const doLoadPaymentProviderUrl = async (provider) => {
        let response;
        try {
          response = await axios.get("/paymentProviders/" + provider)
        } catch (errorReq) {
          console.log(errorReq);
        }
        return response?.data;
      }

    const {mutateAsync: loadPaymentProviders, data, isLoading: isLoadingPaymentProviders} = useMutation({mutationFn: doLoadPaymentProviders})

    const {mutateAsync: loadPaymentProviderUrl, data : url, isLoading: isLoadingPaymentProvider} = useMutation({mutationFn: doLoadPaymentProviderUrl})

    const loadProviderUrl = (provider) => {
      let loadingProvs = {...loadingPaymentUrl};
      loadingProvs[provider] = true;
      setLoadingPaymentUrl(loadingProvs);
      loadPaymentProviderUrl(provider,{onSuccess:(data)=> {
        let loadingProvs = {...loadingPaymentUrl};
        loadingProvs[provider] = false;
        setLoadingPaymentUrl(loadingProvs);
        window.location.assign(data.url);
      }})
    }

    useEffect(()=> {
      loadPaymentProviders();
    },[]);

    return (
      <>
        <Row justify={'center'}>
          <Col><Title level={4}>Select payment provider</Title></Col>
        </Row>
        {isLoadingPaymentProviders || !data && <Row style={{marginBottom:16}} justify={'center'}> <Col><Spin></Spin></Col></Row>}
        {!isLoadingPaymentProviders && data && 
          data.paymentProviders.map((provider)=> {
              return (<Row style={{marginBottom:16}} justify={'center'}><Button style={{minWidth:300}} onClick={()=> {loadProviderUrl(provider)}} loading={loadingPaymentUrl?loadingPaymentUrl[provider]:false} icon={<img style={{height: 20}} src={providerIcons[provider]}></img>} ></Button></Row>);
          })
          
        }
      </>
    );
}