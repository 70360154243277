
import { useKeycloak } from '@react-keycloak/web';
import { useMutation } from '@tanstack/react-query';
import {Row, Col, Result, Spin } from 'antd';
import { ActualOptions } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/images/logo_anilorak.svg';
import { useAxios } from '../hooks/axios-hook';
import Subscribe from './Subscribe';
import PremiumUser from './PremiumUser'
export default () => {

    const {t} = useTranslation();
    const { keycloak, initialized } = useKeycloak();
    const {axios, initialized: axiosInitialized, setToken} = useAxios();
    const navigate = useNavigate();

    const doLoadMe = async () => {
        let response;
        try {
          response = await axios.get("/keycloak/me")
        } catch (errorReq) {
          console.log(errorReq);
        }
        return response?.data;
      }

    const {mutateAsync: loadMe, data: user, isLoading: isLoadingUser} = useMutation({mutationFn: doLoadMe})

    useEffect(()=> {
        if(initialized && !keycloak.authenticated){
            navigate("/");
        } else {
            setToken(keycloak.token);
        }
    },[initialized]);

    useEffect(()=> {
        if(axiosInitialized){
            if(axiosInitialized){
                loadMe();
            }
        }
    },[axiosInitialized])

    return (
        <Result icon={<img src={logo} alt="Logo" />} title={isLoadingUser || !user ? <Spin size="large"></Spin> : t("welcomeUser", {user})}>
                {user?.premium && <PremiumUser user={user}/>}           
                {!user?.premium && axiosInitialized && <Subscribe axios={axios} user={user}/>}           
        </Result>
    );
}