import { Button, Result } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate, useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  const {t} = useTranslation();
    const navigate = useNavigate();
    
    const goHome = ()=> {
        navigate("/");
    }

  return (
    <Result
    status="500"
    title={t('error.title')}
    subTitle={t('error.subtitle')}
    extra={<Button onClick={goHome} type="primary">{t('error.goBack')}</Button>}
  />
  );
}