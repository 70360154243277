import './App.css';
import { Button, ConfigProvider, Result, Space } from 'antd';
import logo from './assets/images/logo_anilorak.svg';
import Welcome from './components/Welcome';
import Login from './components/Login';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import ErrorPage from './components/ErrorPage';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

function App() {
  const queryClient = new QueryClient()
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Login />,
      errorElement: <ErrorPage />
    },
    {
      path: "/welcome",
      element: <Welcome />,
      errorElement: <ErrorPage />
    },
  ]);

  return (
    <QueryClientProvider client={queryClient}>
    <ConfigProvider
    theme={{
      token: {
        // Seed Token
        colorPrimary: 'rgb(112, 93, 74)',
        borderRadius: 4,
      },
    }}
  >
      <RouterProvider router={router} />
  </ConfigProvider>
  </QueryClientProvider>
  );
}

export default App;
