import axios from 'axios';
import { useState, useEffect } from 'react';
import { useAuth } from './auth-hook';

/**
 * Returns an authorizated axios instance
 *
 * @param {Object} config is the default config to be sent to the axios creator
 *
 * @return {Object} an object containing the axios instance and the initialized prop
 *
 */
export const useAxios = (config = {}) => {
  const [initialized, setInitialized] = useState(false);
  const [axiosInstance, setAxiosInstance] = useState({});
  const [token, setToken] = useState();

  useEffect(() => {
    const instance = axios.create({
      ...config,
      baseURL: process.env.REACT_APP_API_URL,
      withCredentials: false,
      headers: {
        ...(config.headers || {}),
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: token ? `Bearer ${token}` : undefined,
      },
    });
    setAxiosInstance({ instance });
    setInitialized(true);

    return () => {
      setAxiosInstance({});
      setInitialized(false);
    };
  }, [token]);

  return { axios: axiosInstance.instance, initialized, setToken };
};

export default { useAxios };