
import { useKeycloak } from '@react-keycloak/web';
import { Button, Result } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/images/logo_anilorak.svg';

export default () => {

    const {t} = useTranslation();
    const { keycloak, initialized } = useKeycloak();
    const navigate = useNavigate();
    const begin = () => {

        if(!keycloak.authenticated){
            keycloak.login();
        } else {
            navigate("/welcome");
        }
    }

    useEffect(()=> {
        if(initialized && keycloak.authenticated){
            navigate("/welcome");
        }
    }, [keycloak, initialized]);

    return (<Result
    icon={<img src={logo} alt="Logo" />}
    title={t('welcome')}
    extra={<Button onClick={begin} loading={!initialized} type="primary">{t('next')}</Button>}
  />);
}